export interface SmartTissueUserValues {
    epithelial: number;
    granulation: number;
    slough: number;
    eschar: number;
}

export const QUESTION_CODE = {
    wasWoundCareProvided: 'wasWoundCareProvided',

    // BWAT
    tissueSlough: 'tissueSlough',
    tissueEschar: 'tissueEschar',

    // PUSH
    epithelialExists: 'epithelialExists',
    granulationExists: 'granulationExists',
    sloughExists: 'sloughExists',
    escharExists: 'escharExists',

    tissueEpithelialAmount: 'tissueEpithelialAmount',
    tissueGranulationAmount: 'tissueGranulationAmount',
    tissueSloughAmount: 'tissueSloughAmount',
    tissueEscharAmount: 'tissueEscharAmount',
};

export const ANSWER_CODE = {
    yes: 'yes',
    no: 'no',

    // BWAT scoring type

    whiteNonAdherentYellow: 'whiteNonAdherentYellow',
    tissueEscharAdherantSoftBlack: 'tissueEscharAdherantSoftBlack',

    tissueEpithelial100: 'tissueEpithelial100',
    tissueEpithelial75To100: 'tissueEpithelial75To100',
    tissueEpithelial50To75: 'tissueEpithelial50To75',
    tissueEpithelial25To50: 'tissueEpithelial25To50',
    tissueEpithelial0To25: 'tissueEpithelial0To25',

    tissueGranulationIntact: 'tissueGranulationIntact',
    tissueGranulation100: 'tissueGranulation100',
    tissueGranulation75To100: 'tissueGranulation75To100',
    tissueGranulation25To75: 'tissueGranulation25To75',
    tissueGranulation0To25: 'tissueGranulation0To25',
    tissueGranulation0: 'tissueGranulation0',

    tissueSlough100: 'tissueSlough100',
    tissueSlough75To100: 'tissueSlough75To100',
    tissueSlough50To75: 'tissueSlough50To75',
    tissueSlough25To50: 'tissueSlough25To50',
    tissueSlough0To25: 'tissueSlough0To25',
    tissueSlough0: 'tissueSlough0',

    tissueEschar100: 'tissueEschar100',
    tissueEschar75To100: 'tissueEschar75To100',
    tissueEschar50To75: 'tissueEschar50To75',
    tissueEschar25To50: 'tissueEschar25To50',
    tissueEschar0To25: 'tissueEschar0To25',
    tissueEschar0: 'tissueEschar0',

    // PUSH scoring type

    tissueEpithelial0: 'tissueEpithelial0',
    tissueEpithelial10: 'tissueEpithelial10',
    tissueEpithelial20: 'tissueEpithelial20',
    tissueEpithelial30: 'tissueEpithelial30',
    tissueEpithelial40: 'tissueEpithelial40',
    tissueEpithelial50: 'tissueEpithelial50',
    tissueEpithelial60: 'tissueEpithelial60',
    tissueEpithelial70: 'tissueEpithelial70',
    tissueEpithelial80: 'tissueEpithelial80',
    tissueEpithelial90: 'tissueEpithelial90',
    // tissueEpithelial100: 'tissueEpithelial100',

    // tissueGranulation0: 'tissueGranulation0',
    tissueGranulation10: 'tissueGranulation10',
    tissueGranulation20: 'tissueGranulation20',
    tissueGranulation30: 'tissueGranulation30',
    tissueGranulation40: 'tissueGranulation40',
    tissueGranulation50: 'tissueGranulation50',
    tissueGranulation60: 'tissueGranulation60',
    tissueGranulation70: 'tissueGranulation70',
    tissueGranulation80: 'tissueGranulation80',
    tissueGranulation90: 'tissueGranulation90',
    // tissueGranulation100: 'tissueGranulation100',

    // tissueSlough0: 'tissueSlough0',
    tissueSlough10: 'tissueSlough10',
    tissueSlough20: 'tissueSlough20',
    tissueSlough30: 'tissueSlough30',
    tissueSlough40: 'tissueSlough40',
    tissueSlough50: 'tissueSlough50',
    tissueSlough60: 'tissueSlough60',
    tissueSlough70: 'tissueSlough70',
    tissueSlough80: 'tissueSlough80',
    tissueSlough90: 'tissueSlough90',
    // tissueSlough100: 'tissueSlough100',

    // tissueEschar0: 'tissueEschar0',
    tissueEschar10: 'tissueEschar10',
    tissueEschar20: 'tissueEschar20',
    tissueEschar30: 'tissueEschar30',
    tissueEschar40: 'tissueEschar40',
    tissueEschar50: 'tissueEschar50',
    tissueEschar60: 'tissueEschar60',
    tissueEschar70: 'tissueEschar70',
    tissueEschar80: 'tissueEschar80',
    tissueEschar90: 'tissueEschar90',
    // tissueEschar100: 'tissueEschar100',
};
