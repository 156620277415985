import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { LhcDataService } from '../../lib/lhc-data.service';
import { WindowService } from '../../lib/window.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  SmartTissue,
  SmartTissueImage,
  SmartTissueItem,
} from '../../lib/models/smartTissue.interface';
import { SmartTissueService } from '../../lib/smart-tissue.service';
import { SmartTissueService2 } from '../../lib/smart-tissue.service-2';
import { BodyLocationService } from '../../lib/body-location.service';
import { BodyLocation } from '../../lib/models/bodyLocation.interface';
import { CognitivelyImpairedPainService } from '../../lib/cognitively-impaired-pain.service';
import { QUESTION_CODE } from '../../lib/models/wound-measure.interface';
import { WoundCareOrderService } from '../../lib/wound-care-order.service';
import { WoundCareOrder } from '../../lib/models/woundCareOrder.interface';
import { QuestionnaireDataService } from '../../lib/questionnaire-data.service';
import { QuestionnaireData } from '../../lib/models/questionnaire.interface';

@Component({
  selector: 'lhc-item',
  templateUrl: './lhc-item.component.html',
  styleUrls: ['./lhc-item.component.css'],
})
export class LhcItemComponent {
  @Input() item;
  public questionCode = QUESTION_CODE;

  viewMode = '';
  // This variable is used to get the Smart Tissue data from the LHC Data Service
  public smartTissue$: Observable<SmartTissue> =
    this.smartTissueService.getSmartTissue();
  public showSmartTissue$: Observable<boolean> = this.smartTissue$.pipe(
    map((data) => data?.show)
  );
  public items$: Observable<SmartTissueItem[]> = this.smartTissue$.pipe(
    map((data) => data?.items)
  );
  public image$: Observable<SmartTissueImage> = this.smartTissue$.pipe(
    map((data) => data?.image)
  );
  public imageMask$: Observable<SmartTissueImage> = this.smartTissue$.pipe(
    map((data) => data?.imageMask)
  );
  public smartTissueHasFailed$: Observable<boolean> = this.smartTissue$.pipe(
    map((data) => data?.hasFailed)
  );

  // This variable is used to get the Body Location data
  public bodyLocation$: Observable<BodyLocation | null> =
    this.bodyLocationService.getBodyLocation();

  // This variable is used to get the Wound Care Order data
  public woundCareOrder$: Observable<WoundCareOrder | null> =
    this.woundCareOrderService.getOrder();

  // This variable is used to get the Scoring Types from QuestionnaireDataService
  public questionnaireDataService$: Observable<QuestionnaireData | null> =
    this.questionnaireDataService.getQuestionnaireData();

  public scoringTypes$: Observable<string> =
    this.questionnaireDataService$.pipe(
      map((data) => data?.scoringTypes.join(', '))
    );

  bodyLocationQuestionCode = QUESTION_CODE.bodyPicker;
  woundCareOrderQuestionCode = QUESTION_CODE.woundCareOrderPicker;
  smartTissueQuestionCode = QUESTION_CODE.smartTissue;

  constructor(
    private winService: WindowService,
    public lhcDataService: LhcDataService,
    private smartTissueService: SmartTissueService,
    private smartTissueService2: SmartTissueService2,
    private bodyLocationService: BodyLocationService,
    private woundCareOrderService: WoundCareOrderService,
    private cognitivelyImpairedPainService: CognitivelyImpairedPainService,
    private questionnaireDataService: QuestionnaireDataService
  ) {
    winService.windowWidth.subscribe((updatedWidth) => {
      this.viewMode = winService.getViewMode();
    });
  }
}
