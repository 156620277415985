import { SmartTissueUserValues, QUESTION_CODE, ANSWER_CODE } from './models/smartTissueUserValues.interface';
import { Injectable } from '@angular/core';
import { QuestionnaireDataService } from './questionnaire-data.service';

@Injectable({
  providedIn: 'root',
})

export class SmartTissueService2 {

  constructor(private questionnaireDataService: QuestionnaireDataService) {
    window['SmartTissueService2'] = this;
  }

  setSmartTissueUserValues(userValues: SmartTissueUserValues, formData: any): void {
    console.log('SmartTissueService2 - setSmartTissueUserValues', userValues);

    if (!formData || !formData.items) {
      console.log('SmartTissueService2 - setSmartTissueUserValues - formData or formData.items is null');
      return;
    }

    // scoreType
    const questionnaireData = this.questionnaireDataService.getQuestionnaireData();
    let scoringTypes: string[] = null;
    questionnaireData.subscribe((questionnaireData) => {
      console.log('SmartTissueService2 - questionnaireData', questionnaireData);
      if (questionnaireData && questionnaireData.scoringTypes) {
        scoringTypes = questionnaireData.scoringTypes;
      }
    });

    // if scoringTypes is not available, retry after 200 milliseconds
    if (!scoringTypes) {
      console.log('SmartTissueService2 - scoringTypes is not available, retrying after 200 milliseconds');
      setTimeout(() => {
        this.setSmartTissueUserValues(userValues, formData);
      }, 200);
      return;
    }

    console.log('SmartTissueService2 - scoringTypes', scoringTypes);

    for (var scoringType of scoringTypes) {
      if (scoringType) {
        if (scoringType?.toLowerCase() === "push") {
          this.handlePushScore(userValues, formData);
        } else if (scoringType?.toLowerCase() === "bwat") {
          this.handleBwatScore(userValues, formData);
        }
      }
    }
  }

  private handleBwatScore(userValues: SmartTissueUserValues, formData: any): boolean {
    console.log('SmartTissueService2 - handleBwatScore', userValues);

    let updated = false;

    // Map body location data to specific question codes
    const answers = this.getBwatAnswers(userValues);

    // Update each field if data is available
    for (const [questionCode, answer] of answers.entries()) {
      if (answer) {
        const setAsReadOnly =
          questionCode !== QUESTION_CODE.wasWoundCareProvided &&
          questionCode !== QUESTION_CODE.tissueSlough &&
          questionCode !== QUESTION_CODE.tissueEschar;

        console.log('SmartTissueService2 - questionCode', questionCode);
        console.log('SmartTissueService2 - answer', answer);
        updated = this.findAndUpdateItemByQuestionCode(
          formData.items,
          questionCode,
          answer,
          setAsReadOnly
        ) || updated;
      }
    }

    return updated;
  }

  private getBwatAnswers(userValues: SmartTissueUserValues): Map<string, string> {
    // map values to 1-100 scale
    const epithelial = userValues.epithelial * 100;
    const granulation = userValues.granulation * 100;
    const slough = userValues.slough * 100;
    const eschar = userValues.eschar * 100;

    const answers = new Map<string, string>();

    // displaying smart tissue values depends on the answers to these questions
    // these could be removed when these conditions will not be needed
    answers.set(QUESTION_CODE.wasWoundCareProvided, ANSWER_CODE.yes);
    answers.set(QUESTION_CODE.tissueSlough, ANSWER_CODE.whiteNonAdherentYellow);
    answers.set(QUESTION_CODE.tissueEschar, ANSWER_CODE.tissueEscharAdherantSoftBlack);

    // Handle tissue epithelial amount ranges
    if (epithelial !== undefined) {
      let tissueEpithelialAmountAnswer: string;

      if (epithelial === 100) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial100;
      } else if (epithelial >= 75 && epithelial < 100) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial75To100;
      } else if (epithelial >= 50 && epithelial < 75) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial50To75;
      } else if (epithelial >= 25 && epithelial < 50) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial25To50;
      } else if (epithelial >= 0 && epithelial < 25) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial0To25;
      } else {
        tissueEpithelialAmountAnswer = '';
      }

      if (tissueEpithelialAmountAnswer) {
        answers.set(QUESTION_CODE.tissueEpithelialAmount, tissueEpithelialAmountAnswer);
      }
    }

    // Handle tissue granulation amount ranges
    if (granulation !== undefined) {
      let tissueGranulationAmountAnswer: string;

      if (granulation === 100) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation100;
      } else if (granulation >= 75 && granulation < 100) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation75To100;
      } else if (granulation >= 25 && granulation < 75) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation25To75;
      } else if (granulation >= 0 && granulation < 25) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation0To25;
      } else if (granulation === 0) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation0;
      } else {
        tissueGranulationAmountAnswer = '';
      }

      if (tissueGranulationAmountAnswer) {
        answers.set(QUESTION_CODE.tissueGranulationAmount, tissueGranulationAmountAnswer);
      }
    }

    // Handle tissue slough amount ranges
    if (slough !== undefined) {
      let tissueSloughAmountAnswer: string;

      if (slough === 100) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough100;
      } else if (slough >= 75 && slough < 100) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough75To100;
      } else if (slough >= 50 && slough < 75) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough50To75;
      } else if (slough >= 25 && slough < 50) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough25To50;
      } else if (slough >= 0 && slough < 25) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough0To25;
      } else if (slough === 0) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough0;
      } else {
        tissueSloughAmountAnswer = '';
      }

      if (tissueSloughAmountAnswer) {
        answers.set(QUESTION_CODE.tissueSloughAmount, tissueSloughAmountAnswer);
      }
    }

    // Handle tissue eschar amount ranges
    if (eschar !== undefined) {
      let tissueEscharAmountAnswer: string;

      if (eschar === 100) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar100;
      } else if (eschar >= 75 && eschar < 100) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar75To100;
      } else if (eschar >= 50 && eschar < 75) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar50To75;
      } else if (eschar >= 25 && eschar < 50) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar25To50;
      } else if (eschar >= 0 && eschar < 25) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar0To25;
      } else if (eschar === 0) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar0;
      } else {
        tissueEscharAmountAnswer = '';
      }

      if (tissueEscharAmountAnswer) {
        answers.set(QUESTION_CODE.tissueEscharAmount, tissueEscharAmountAnswer);
      }
    }

    return answers;
  }

  private handlePushScore(userValues: SmartTissueUserValues, formData: any): boolean {
    console.log('SmartTissueService2 - handlePushScore', userValues);

    let updated = false;

    // Map body location data to specific question codes
    const answers = this.getPushAnswers(userValues);

    // Update each field if data is available
    for (const [questionCode, answer] of answers.entries()) {
      if (answer) {
        const setAsReadOnly = true;

        console.log('SmartTissueService2 - questionCode', questionCode);
        console.log('SmartTissueService2 - answer', answer);
        updated = this.findAndUpdateItemByQuestionCode(
          formData.items,
          questionCode,
          answer,
          setAsReadOnly
        ) || updated;
      }
    }

    return updated;
  }

  private getPushAnswers(userValues: SmartTissueUserValues): Map<string, string> {
    // map values to 1-100 scale
    const epithelial = userValues.epithelial * 100;
    const granulation = userValues.granulation * 100;
    const slough = userValues.slough * 100;
    const eschar = userValues.eschar * 100;

    const answers = new Map<string, string>();

    // displaying smart tissue values depends on the answers to these questions
    // these could be removed when these conditions will not be needed
    answers.set(QUESTION_CODE.epithelialExists, ANSWER_CODE.yes);
    answers.set(QUESTION_CODE.granulationExists, ANSWER_CODE.yes);
    answers.set(QUESTION_CODE.sloughExists, ANSWER_CODE.yes);
    answers.set(QUESTION_CODE.escharExists, ANSWER_CODE.yes);

    // Handle tissue epithelial amount ranges
    if (epithelial !== undefined) {
      let tissueEpithelialAmountAnswer: string;

      if (epithelial === 100) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial100;
      } else if (epithelial >= 90 && epithelial < 100) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial90;
      } else if (epithelial >= 80 && epithelial < 90) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial80;
      } else if (epithelial >= 70 && epithelial < 80) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial70;
      } else if (epithelial >= 60 && epithelial < 70) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial60;
      } else if (epithelial >= 50 && epithelial < 60) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial50;
      } else if (epithelial >= 40 && epithelial < 50) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial40;
      } else if (epithelial >= 30 && epithelial < 40) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial30;
      } else if (epithelial >= 20 && epithelial < 30) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial20;
      } else if (epithelial >= 10 && epithelial < 20) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial10;
      } else if (epithelial >= 0 && epithelial < 10) {
        tissueEpithelialAmountAnswer = ANSWER_CODE.tissueEpithelial0;
      } else {
        tissueEpithelialAmountAnswer = '';
      }

      if (tissueEpithelialAmountAnswer) {
        answers.set(QUESTION_CODE.tissueEpithelialAmount, tissueEpithelialAmountAnswer);
      }
    }

    // Handle tissue granulation amount ranges
    if (granulation !== undefined) {
      let tissueGranulationAmountAnswer: string;

      if (granulation === 100) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation100;
      } else if (granulation >= 90 && granulation < 100) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation90;
      } else if (granulation >= 80 && granulation < 90) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation80;
      } else if (granulation >= 70 && granulation < 80) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation70;
      } else if (granulation >= 60 && granulation < 70) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation60;
      } else if (granulation >= 50 && granulation < 60) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation50;
      } else if (granulation >= 40 && granulation < 50) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation40;
      } else if (granulation >= 30 && granulation < 40) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation30;
      } else if (granulation >= 20 && granulation < 30) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation20;
      } else if (granulation >= 10 && granulation < 20) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation10;
      } else if (granulation >= 0 && granulation < 10) {
        tissueGranulationAmountAnswer = ANSWER_CODE.tissueGranulation0;
      } else {
        tissueGranulationAmountAnswer = '';
      }

      if (tissueGranulationAmountAnswer) {
        answers.set(QUESTION_CODE.tissueGranulationAmount, tissueGranulationAmountAnswer);
      }
    }

    // Handle tissue slough amount ranges
    if (slough !== undefined) {
      let tissueSloughAmountAnswer: string;

      if (slough === 100) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough100;
      } else if (slough >= 90 && slough < 100) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough90;
      } else if (slough >= 80 && slough < 90) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough80;
      } else if (slough >= 70 && slough < 80) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough70;
      } else if (slough >= 60 && slough < 70) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough60;
      } else if (slough >= 50 && slough < 60) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough50;
      } else if (slough >= 40 && slough < 50) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough40;
      } else if (slough >= 30 && slough < 40) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough30;
      } else if (slough >= 20 && slough < 30) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough20;
      } else if (slough >= 10 && slough < 20) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough10;
      } else if (slough >= 0 && slough < 10) {
        tissueSloughAmountAnswer = ANSWER_CODE.tissueSlough0;
      } else {
        tissueSloughAmountAnswer = '';
      }

      if (tissueSloughAmountAnswer) {
        answers.set(QUESTION_CODE.tissueSloughAmount, tissueSloughAmountAnswer);
      }
    }

    // Handle tissue eschar amount ranges
    if (eschar !== undefined) {
      let tissueEscharAmountAnswer: string;

      if (eschar === 100) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar100;
      } else if (eschar >= 90 && eschar < 100) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar90;
      } else if (eschar >= 80 && eschar < 90) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar80;
      } else if (eschar >= 70 && eschar < 80) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar70;
      } else if (eschar >= 60 && eschar < 70) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar60;
      } else if (eschar >= 50 && eschar < 60) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar50;
      } else if (eschar >= 40 && eschar < 50) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar40;
      } else if (eschar >= 30 && eschar < 40) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar30;
      } else if (eschar >= 20 && eschar < 30) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar20;
      } else if (eschar >= 10 && eschar < 20) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar10;
      } else if (eschar >= 0 && eschar < 10) {
        tissueEscharAmountAnswer = ANSWER_CODE.tissueEschar0;
      } else {
        tissueEscharAmountAnswer = '';
      }

      if (tissueEscharAmountAnswer) {
        answers.set(QUESTION_CODE.tissueEscharAmount, tissueEscharAmountAnswer);
      }
    }

    return answers;
  }

  /**
    * Recursively find and update an item by question code
    * @param items Array of form items to search
    * @param questionCode The question code to find
    * @param newValue The new value to set
    * @returns {boolean} True if the item was found and updated, false otherwise
    */
  private findAndUpdateItemByQuestionCode(items: any[], questionCode: string, newValue: any, setAsReadOnly = false): boolean {
    // console.log('findAndUpdateItemByQuestionCode - items', items);
    if (!items || !Array.isArray(items)) return false;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      // Check if this is the item we're looking for
      if (item.questionCode === questionCode) {

        // Handle different data types appropriately
        if (item.dataType === 'BL') {
          // Boolean type
          try {
            item.value = typeof newValue === 'boolean' ? newValue : JSON.parse(newValue);
          } catch (error) {
            item.value = null;
          }
        } else if (item.dataType === 'CNE' || item.dataType === 'CWE') {
          // Choice type (single or multiple)
          if (item._multipleAnswers) {
            // Multiple choice
            try {
              const arrayOfValues = Array.isArray(newValue) ? newValue : [newValue];
              const selectedOptions = [];

              arrayOfValues.forEach((valueItem) => {
                // Try to find by code first
                let option = item.answers.find(answer => answer.code === valueItem);

                // If not found by code, try by text
                if (!option) {
                  option = item.answers.find(answer => answer.text === valueItem);
                }

                if (option) {
                  selectedOptions.push(option);
                }
              });

              item.value = selectedOptions;
            } catch (e) {
              console.error("Error setting multiple choice value:", e);
            }
          } else {
            // Single choice
            let option;

            // Try to find by code first
            option = item.answers.find(answer => answer.code === newValue);

            // If not found by code, try by text
            if (!option) {
              option = item.answers.find(answer => answer.text === newValue);
            }

            item.value = option || null;
            // console.log('findAndUpdateItemByQuestionCode - item EDITABLE', item.editable);
            if (setAsReadOnly) {
              item.editable = "0";
            }
          }
        } else if (item.dataType === 'QTY') {
          // Quantity type
          if (typeof newValue === 'object' && newValue !== null) {
            // If it's an object with value and unit
            item.value = newValue.value;
            if (newValue.unit) {
              item.unit = { name: newValue.unit };
            }
          } else {
            // Just set the value
            item.value = newValue;
          }
        } else {
          // Other types (ST, INT, DT, TM, etc.)
          item.value = newValue;
        }

        // console.log('findAndUpdateItemByQuestionCode - true', item);
        return true;
      }

      // Recursively check child items
      if (item.items && item.items.length > 0) {
        const found = this.findAndUpdateItemByQuestionCode(item.items, questionCode, newValue, setAsReadOnly);
        if (found) return true;
      }
    }

    // console.log('findAndUpdateItemByQuestionCode - false', items, questionCode, newValue);
    return false;
  }
}
